(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/execute-payment/assets/javascripts/execute-payment.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/execute-payment/assets/javascripts/execute-payment.js');
"use strict";


const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;

const executePayment = async (paymentBuilder, context) => {
  const [result, error] = await paymentBuilder.execute(context);
  const paidWagers = result.getPaidPayableItems();
  paidWagers.forEach(wager => {
    if (wager.activatedDrawId) {
      getAccountBalance.deleteBalance(wager.activatedDrawId);
    }
  });
  return [result, error];
};
setGlobal('svs.components.wagerPaymentFlow.executePayment.executePayment', executePayment);

 })(window);